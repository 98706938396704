
import { Component, Prop, Vue } from 'vue-property-decorator';
import { inject } from '@/inversify';
import MarketsCalendarItem from '@/modules/markets/components/calendar/markets-calendar-item.vue';
import type MarketsService from '@/modules/markets/markets.service';
import Calendar from '@/modules/common/components/ui-kit/calendar.vue';
import { KEY } from '@/inversify.keys'; import type DocumentFiltersService from '@/modules/document-filters/document-filters.service';
import MarketsCalendarItemNoData from '@/modules/markets/components/calendar/markets-calendar-item-no-data.vue';
import MarketsCalendarItemEmpty from '@/modules/markets/components/calendar/markets-calendar-item-empty.vue';
import MarketsCalendarItemNA from '@/modules/markets/components/calendar/markets-calendar-item-na.vue';
import LoaderWrapper from '@/modules/common/components/loader-wrapper.vue';
import type UserService from '@/modules/user/user.service';

@Component({
    components: {
        Calendar,
        MarketsCalendarItem,
        MarketsCalendarItemNoData,
        MarketsCalendarItemEmpty,
        MarketsCalendarItemNA,
        LoaderWrapper,
    },
})
export default class MarketsCalendar extends Vue {
    @inject(KEY.MarketsService) marketsService!: MarketsService;
    @inject(KEY.DocumentFiltersService) private documentFiltersService!: DocumentFiltersService;
    @inject(KEY.UserService) private userService!: UserService;

    @Prop({
        type: String,
    })
    source!: string;

    @Prop({
        type: Boolean,
    })
    skeleton!: boolean;

    get year() {
        return this.documentFiltersService.storeState.settings.year;
    }

    get month() {
        return this.documentFiltersService.storeState.settings.month;
    }

    get hotelId() {
        return this.userService.currentHotelId;
    }

    emitHover(e: MouseEvent) {
        this.$emit('cellover', e);
    }

    emitLeave(e: MouseEvent) {
        this.$emit('cellout', e);
    }
}
