
import { Component, Prop, Vue } from 'vue-property-decorator';
import { inject } from '@/inversify';

import ScanBtn from '@/modules/common/components/ui-kit/scan-btn.vue';

import { KEY } from '@/inversify.keys'; import type DocumentFiltersService from '@/modules/document-filters/document-filters.service';
import type UserService from '@/modules/user/user.service';
import type CompsetsService from '@/modules/compsets/compsets.service';
import type MarketsService from '../markets.service';
import SCAN_STATUS from '../constants/scan-status.constant';

@Component({ components: { ScanBtn } })
export default class MarketsScan extends Vue {
    @inject(KEY.MarketsService) private marketsService!: MarketsService;
    @inject(KEY.DocumentFiltersService) private documentFiltersService!: DocumentFiltersService;
    @inject(KEY.UserService) private userService!: UserService;
    @inject(KEY.CompsetsService) private compsetsService!: CompsetsService;

    @Prop({
        type: String,
    })
    private provider!: string;

    @Prop({
        type: Boolean,
        default: false,
    })
    public skeleton!: boolean;

    get isScanning() {
        return this.marketsService.scanStatus(this.provider) === SCAN_STATUS.IN_PROGRESS;
    }

    get disabled() {
        if (!this.compsetsService.currentCompset?.isActive) return true;

        return !this.marketsService.isScanAvailable();
    }

    get lastScanDate() {
        return this.marketsService.finishScanDate(this.provider);
    }

    get title() {
        // FIXME: Replace with i18n key
        return this.disabled && this.documentFiltersService.isPreviousMonth
            ? 'Not allowed to scan previous months'
            : '';
    }

    async handleClick() {
        if (this.isScanning || this.disabled) {
            return;
        }
        this.$gtag.event('on-demand', {
            chainId: this.userService.chainId,
            userId: this.userService.id,
        });

        const settings = {
            provider: this.provider,
            compsetId: this.documentFiltersService.settings.compsetId,
            los: this.documentFiltersService.settings.los,
            pos: this.documentFiltersService.settings.pos,
        };

        await this.marketsService.triggerScan(settings);
    }
}
