
import { Component, Vue, Prop } from 'vue-property-decorator';
import { inject } from '@/inversify';

import type Day from '@/modules/common/types/day.type';
import isDayType from '@/modules/common/component-validators/is-date.validator';

import { KEY } from '@/inversify.keys'; import type DocumentFiltersService from '@/modules/document-filters/document-filters.service';
import type UserService from '@/modules/user/user.service';
import type MarketsService from '../../markets.service';

@Component
export default class MarketsCalendarItemNoData extends Vue {
    @inject(KEY.MarketsService) private marketsService!: MarketsService;
    @inject(KEY.UserService) userService!: UserService;
    @inject(KEY.DocumentFiltersService) private documentFiltersService!: DocumentFiltersService;

    @Prop({
        required: true,
        type: Number,
        validator: isDayType,
    })
    private day!: Day;

    @Prop({
        required: true,
        type: String,
    })
    private source!: string;

    get isOutOfRange() {
        return this.marketsService.isOutOfRange(this.source);
    }

    get dayRoute() {
        const isDayMarketsOpened = this.$route.name!.includes('day-markets-source');
        return {
            name: isDayMarketsOpened ? this.$route.name! : `${this.$route.name!}.day-markets-source`,
            params: {
                day: String(this.day),
                source: String(this.source),
                compsetId: this.documentFiltersService.settings.compsetId,
            },
        };
    }
}
